.App-logo {animation: App-logo-spin infinite 20s linear;height: 80px;}
.App-header {background-color: #140a9a;height: 85px;/*padding: 20px;*/color: white;}
.App-title {font-size: 1.5em;margin-top: 0.5rem;}
.App-intro {font-size: large;}

.displayTable {
  margin-top: 10px;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.title {
  text-align: center;
}



.chart {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 100px;
  margin-left: 100px;
}



.cards {
  margin-bottom: 15px;
}

.card {
  margin: 15px;
}

.card button {
  margin-right: 10px;
}

.ern {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  margin-bottom: 10px;
}

.ern a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
}

.css-jxiqlq {
  position: relative;
  margin: 50px auto;
}

#no-result {
  color: black;
  position: absolute;
  margin-top: 20px;
}

#reset {
  margin-top: 5px;
}

.buttonWrapper {
  margin-bottom: 10px;
}

.para {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 25pt;
}

.btn-primary {
  background-color: #140a9a;
  border-color: #140a9a;
}

.btn-secondary {
  background-color: #140a9a;
  border-color: #140a9a;
}

.tb {
  margin-left: 0;
}

.react-datepicker-ignore-onclickoutside {
  margin-left: 0;
}


#output {
  margin-left: 10px;
}

.search-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navBtn {color: #140a9a;background: rgba(190, 194, 255,0.5);}
.navBtn:hover {background-color: #CFDDE6;}
button.navBtn.active {background-color: #f2f6f7;} 

.chart .row > .Column {margin: 5px;padding: 5px;position: relative;}
#reset {position: absolute;top: 50%;margin-top: -50%;}

/* FusionCharts SVG Icons Color change */
/* g[class*="toolbar"] g[class*="button"] rect, g[class*="toolbar"] g[class*="button"] path {fill: rgba(14, 18, 83,0.04) !important;} */
g[class*="toolbar"] g[class*="button"] rect[stroke="#e3e3e3"], g[class*="toolbar"] g[class*="button"] path[stroke="#e3e3e3"]{opacity: 0.3;}

::selection {
  background: #140a9a; /* WebKit/Blink Browsers */
  color: white;
}

::-moz-selection {
  background: #140a9a; /* Gecko Browsers */
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

