* {
  box-sizing: border-box;
}

* * {
  box-sizing: border-box;
}

#check {
  position: absolute;
  z-index: -9999;
  top: -999px;
}

.btn1 {
  width: 35px;
  height: 30px;
  display: block;
  margin: auto;
  position: relative;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .btn1 {
    width: 30px;
    height: 25px;
  }
}

@media screen and (max-width: 400px) {
  .btn1 {
    width: 25px;
    height: 20px;
  }
}

.btn1 > span {
  width: 100%;
  height: 15%;
  background-color: white;
  display: block;
  position: absolute;
  border-radius: 2px;
}

.btn1 > span:nth-of-type(1) {
  top: 0%;
  transition: top 60ms linear 140ms, transform 60ms ease;
}

.btn1 > span:nth-of-type(2) {
  top: 40%;
  transition: width 1ms linear 60ms;
}

.btn1 > span:nth-of-type(3) {
  top: 80%;
  transition: top 60ms linear 140ms, transform 60ms ease;
}

#check:checked ~ label > .btn1 > span:nth-of-type(1) {
  top: 40%;
  transform: rotate(45deg);
  transition: top linear 60ms, transform 60ms ease 140ms;
}

#check:checked ~ label > .btn1 > span:nth-of-type(2) {
  width: 0;
}

#check:checked ~ label > .btn1 > span:nth-of-type(3) {
  top: 40%;
  transform: rotate(-45deg);
  transition: top linear 70ms, transform 60ms ease 140ms;
}

label {
  margin: 0;
}

.side-nav-toggle {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.side-nav-shelf {
  position: absolute;
  left: 0;
  top: var(--nh, 0);  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #fafafa;
  height: 0;  
  width: 0; 
  transition: all 100ms ease-in-out;
  overflow: hidden;
  z-index: 2000;
}

.side-nav-shelf.open {
  overflow: hidden;
  width: 420px;
  max-width: 100%;
  padding-top: 12px;
  height: calc(100vh - var(--nh, 0));
}

.side-nav-overlay {
  position: absolute;
  left: 0;
  top: var(--nh, 0);
  width: 100%;
  height: 0;
  z-index: -2000;
  background-color: rgba(0,0,0,0.7);
}

.side-nav-overlay.active {
  z-index: 1999;
  height: calc(100vh - var(--nh, 0));
}

a.MuiListItem-root.active::after {
  content: "";
  width: 150px;
  position: absolute;
  left: 52px;
  bottom: 0;
  height: 2px;
  background-color: #140a9a;
}

.MuiListItem-root.side-nav-item:not(.active) span::after {
  content: "";
  padding-top: 2px;
  width: 0;
  background-color: #140a9a;
  position: absolute;
  bottom: 0;
  left: 52px;
} 

.MuiListItem-root.side-nav-item:not(.active):hover { 
  text-shadow: 1px 0 0;
  background-color: rgba(0,0,0,0.04);
}

.MuiListItem-root.side-nav-item:not(.active):hover span::after {
  width: 150px;
}

.MuiButton-root.side-nav-item {
  cursor: pointer;
  padding-left: 10px;
  position: relative;
  color: #140a9a;
  margin: 0 auto;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  width: 100%;
  text-decoration: none;
}

.MuiButton-root.side-nav-item:not(.active)::after {
  content: "";
  padding-top: 2px;
  width: 0;
  background-color: #140a9a;
  position: absolute;
  bottom: 0;
  left: 42px;  
}

.side-nav-item.override:hover {
  background-color: inherit;
}

.MuiButton-label.MuiButton-label {
  justify-content: flex-start;
}

.MuiButton-startIcon.MuiButton-startIcon {
  margin-right: 15px;
}

.MuiButton-root.side-nav-item:not(.active):hover::after {
  width: 150px;  
}

.MuiButton-root.side-nav-item.active {
  font-weight: bold;
  cursor: default;
  background-color: inherit;
}

.MuiButton-root.side-nav-item.active:hover {
  background-color: inherit;
}

.MuiButton-root.side-nav-item.active::after {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 42px;
  width: 150px;
  padding-top: 2px;
  background-color: #140a9a;
}

.MuiButton-root.side-nav-item:not(.active):hover,
.MuiButton-root.side-nav-item:active {
  text-decoration: none;
  text-shadow: 1px 0 0;
}

.MuiButton-root.side-nav-item.override:hover::after {
  width: 0;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto; 
}

@media screen and (max-width: 1024px) {
  nav .container {
    max-width: 750px;
  }
}

@media screen and (max-width: 675px){
  nav .container {
    max-width: 350px;
  }
}

.navbar {
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: .5rem 1rem;
}

.navbar {
  background-color: #140a9a;
}

.navbar-header {
    display: grid;
    grid-template-areas: 'logo'
                        'title'; 
    color: white;
}

@media screen and (min-width: 768px){
  .navbar-header {
    max-width: 100%;
    color: white;
    display: grid;
    grid-template-areas: 'logo title';
    grid-template-columns: max-content 1fr;    
  }
}

.navbar-header img {
  max-width: 100%;
  height: auto;
}

.navbar-header > a {
  max-width: 100%;
  display: block;
}

.navbar-header > h1 {
  word-wrap: break-word;  
  font-size: 28px;
  text-align: center;
  margin: auto;
  color: white;
  vertical-align: baseline;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .navbar-header > h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 650px) {
  .navbar-header > h1 {
    font-size: 17px;
  }
}

@media screen and (max-width: 400px) {
  .navbar-header > h1 {
    font-size: 16px;
  }
}